<template>
  <div class="base-page-style">
    <div>
      <el-form :inline="true" class="btnBox">
        <el-form-item label="菜单名称：" class="rig">
          <el-input v-model="search.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </el-form-item>
        <div style="margin: 10px 0">
          <el-button type="primary" @click="add()">新增</el-button>
        </div>
      </el-form>
    </div>
    <el-table
      tooltip-effect="dark"
      style="width: 100%"
      :data="tableData"
      row-key="id"
      border
      :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }"
      v-loading="loading"
    >
      <el-table-column label="菜单名称">
        <template slot-scope="s">
          <div class="scopeName">
            <div class="scopeName">{{ s.row.name }}</div>
            <div v-show="s.row.isAdmin" class="scopeName" style="margin-left:5px;margin-top: 2px;"><img :src="imgUrl" /></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="identifier" label="唯一标识(Name)">
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="100"> </el-table-column>
      <el-table-column prop="isShow" label="显示/隐藏" width="100">
        <template slot-scope="s">
          <el-switch
            v-model="s.row.isShow"
            @change="SetchangeIsShow(s.row.id, s.row.isShow)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="s">
          <el-button type="text" @click="edit(s.row.id)">编辑</el-button>
          <el-button
            type="text"
            @click="add(s.row.id)"
            v-if="s.row.childrenList"
            >添加子菜单</el-button
          >
          <el-button
            type="text"
            class="cell-del-button"
            @click="delConfirm(s.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <AddMenu
      ref="addDialog"
      @getListData="initData"
      :curId="curId"
      :parentId="id"
      :type="type"
    ></AddMenu>
    <!--新增按钮弹框-->
  </div>
</template>

<script>
import Api from "@/api/apiMenuSet";

export default {
  name: "MenuListPage",
  components: {
    AddMenu: () => import("./Add"),
  },
  data() {
    return {
      curId: "",
      id: "",
      formSearch: {}, // 搜素
      tableData: [],
      loading: false,
      // 日志表格
      journalshow: true,
      table: "查看变更日志",
      //日志表格loding
      JTloading: false,
      type: "",
      search: {
        name: "",
      },
      imgUrl: require("@/assets/img/menu.png"),
    };
  },
  filters: {},
  mounted() {
    this.initData();
  },
  methods: {
    // 切换状态
    async SetchangeIsShow(id, isShow) {
      let res = await Api.changeIsShow({ id, isShow });
      if (res.code === "200") {
        this.$message.success(res.msg)
        this.initData();
      }
    },
    // 新增客户
    add(id) {
      if (id) {
        this.id = id;
      } else {
        this.id = "";
      }
      this.curId = "";
      this.type = "add";
      this.$refs.addDialog.open();
    },
    // 编辑客户
    edit(id) {
      this.id = "";
      this.curId = id;
      this.type = "edit";
      this.$refs.addDialog.open();
    },
    /**
     * 列表数据
     */
    async initData() {
      this.loading = true;
      let res = await Api.menuList(this.search);
      if (res.code === "200") {
        this.tableData = res.data;
      }
      this.loading = false;
    },
    /**
     * 搜索
     */
    onSubmit() {
      this.initData();
    },
    /**
     * 重置搜索
     */
    reset() {
      this.search = this.$options.data().search;
      this.initData();
    },
    /**
     * 分页
     */
    pageChange(val) {
      this.page = val;
    },
    /**
     * 删除
     */
    delConfirm(id) {
      this.$confirm("此操作将永久删除该菜单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.del(id);
      }).catch(e=>e);
    },
    async del(id) {
      this.loading = true;
      let res = await Api.deleteInfomation({ id: id });
      if (res.code === "200") {
        this.$message.success(res.msg);
        this.initData();
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rig {
  margin-right: 60px;
}
.btnBox .el-form-item {
  margin-bottom: 0px !important;
}
.scopeName{
  display: inline-block;
  justify-content: center;
  vertical-align: middle;
}
</style>
